import Home from "./components/home";
import React, { useEffect } from "react";
import Footer from "./components/footer";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";
import ProductsPage from "./components/productsPage";
import FactoriesPage from "./components/factoriesPage";

function App() {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    var lang = queryParameters.has("lang") ? queryParameters.get("lang") : "";
    if (lang !== "") {
      i18n.changeLanguage(lang);
      setCookie("lang", lang);
    } else {
      // console.log(cookies.lang);
      lang = cookies.lang;
      i18n.changeLanguage(lang);
    }
    if ((lang !== "" && lang === "ar") || i18n.language === "ar") {
      document.body.classList.add("direction-rtl");
    } else {
      document.body.classList.remove("direction-rtl");
    }
  }, []);
  return (
    <React.Fragment>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/factories" element={<FactoriesPage />} />
        <Route path="/products" element={<ProductsPage />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
