import React from "react";
import { useTranslation } from "react-i18next";

function Logo({ color, className, width, height }) {
  const { i18n } = useTranslation();

  return (
    <>
      {i18n.language === "ar" ? (
        <img
          src={`/assets/images/logo-ar${color}.png`}
          className={className}
          alt="logo"
          width={width}
          height={height}
          loading="lazy"
        />
      ) : (
        <img
          src={`/assets/images/logo-en${color}.png`}
          className={className}
          alt="logo"
          width={width}
          height={height}
          loading="lazy"
        />
      )}
    </>
  );
}
export default Logo;
