import React from "react";
import { useTranslation } from "react-i18next";

function News({ innerRef }) {
  const { t, i18n } = useTranslation();
  return (
    <section id="news" ref={innerRef}>
      {/* <h3>{t("mouTitle")}</h3> */}
      {/* <p className="text-body-secondary">{t("mouDate")}</p> */}

      <div className="row">
        <div className="col-md-6" style={{ lineHeight: "3rem" }}>
          <p>{t("mouDescription")}</p>
          <p>
            <b>{t("mouTitle")}</b>
          </p>
          <a
            href="https://moiat.gov.ae/en/media-center/initiatives/2023/06/07/sinaha/"
            target="_blank"
            className="btn btn-success"
          >
            {t("readMore")}
          </a>
        </div>
        <div className="col-md-6">
          <img
            src="/assets/images/Sinaha1.jpg"
            style={
              i18n.language == "ar"
                ? { objectPosition: "left" }
                : { objectPosition: "right" }
            }
            className="w-100 news-image"
            alt="Products' Smart Catalog"
          />
        </div>
      </div>
    </section>
  );
}

export default News;
