import { React, lazy, Suspense } from "react";
import Header from "./header.";
const Factories = lazy(() => import("./factories"));

function FactoriesPage() {
  return (
    <Suspense fallback={<div>Loading Component</div>}>
      <Header from="factories" />
      <Factories limit={null} from="factories" />
    </Suspense>
  );
}

export default FactoriesPage;
