import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "react-bootstrap/Accordion";

function Faqs({ innerRef }) {
  const { t } = useTranslation();
  return (
    <section id="faqs" ref={innerRef}>
      <h1>{t("faqs")}</h1>
      <p className="text-body-secondary">
        {t("readTheFrequentlyAskedQuestions")}
      </p>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("faq-1")}</Accordion.Header>
          <Accordion.Body>{t("answer-1")}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("faq-2")}</Accordion.Header>
          <Accordion.Body>
            {t("answer-2")}
            <h6 style={{ fontSize: "0.7rem" }}>
              <a
                href="https://moiat.gov.ae/en/services/issue-license-to-use-made-in-the-emirates"
                target="_blank"
              >
                {t("answer-2-note")}
              </a>
            </h6>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{t("faq-3")}</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>{t("answer-3-op-1")}</li>
              <li>{t("answer-3-op-2")}</li>
              <li>{t("answer-3-op-3")}</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{t("faq-4")}</Accordion.Header>
          <Accordion.Body>{t("answer-4")}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{t("faq-5")}</Accordion.Header>
          <Accordion.Body>{t("answer-5")}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>{t("faq-6")}</Accordion.Header>
          <Accordion.Body>
            {t("answer-6")}
            <ul>
              <li>
                <a
                  href="https://moiat.gov.ae/en/services/issue-license-to-use-made-in-the-emirates"
                  target="_blank"
                >
                  {t("answer-6-op-1")}
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=ae.moiat.app&hl=en&gl=US"
                  target="_blank"
                >
                  {t("answer-6-op-2")}
                </a>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/ae/app/moiat/id1578178231"
                  target="_blank"
                >
                  {t("answer-6-op-3")}
                </a>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
}

export default Faqs;
