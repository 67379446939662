import { React, lazy, Suspense } from "react";
import Header from "./header.";
const Products = lazy(() => import("./products"));

function ProductsPage() {
  return (
    <Suspense fallback={<div>Loading Component</div>}>
      <Header from="products" />
      <Products limit={24} source="ProductsPage" />
    </Suspense>
  );
}

export default ProductsPage;
