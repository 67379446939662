import Logo from "./logo";
import { React } from "react";
import Nav from "react-bootstrap/Nav";
import { useCookies } from "react-cookie";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header({
  aboutMarkExecuteScroll,
  factoriesExecuteScroll,
  productsExecuteScroll,
  catalogExecuteScroll,
  contactExecuteScroll,
  galleryExecuteScroll,
  newsExecuteScroll,
  faqsExecuteScroll,
  from,
}) {
  const [cookies, setCookie] = useCookies(["lang"]);
  let current_location = document.location.origin;
  const { t, i18n } = useTranslation();

  const changeLanguage = (event, lang) => {
    // const queryParams = new URLSearchParams(location.search);
    // if (queryParams.has("lang")) {
    //   queryParams.delete("lang");
    // }
    i18n.changeLanguage(lang);
    setCookie("lang", lang);
    if ((lang !== "" && lang === "ar") || i18n.language === "ar") {
      document.body.classList.add("direction-rtl");
    } else {
      document.body.classList.remove("direction-rtl");
    }
  };

  return (
    <header data-bs-theme="dark">
      <Navbar
        expand="lg"
        className="bg-body-tertiary navbar navbar-expand-md navbar-dark fixed-top bg-dark"
      >
        <Container>
          <Navbar.Brand href={current_location}>
            <img
              src="/assets/images/sinaha-logo.png"
              alt="logo"
              width={0}
              height={0}
              loading="lazy"
            />
            &nbsp; | &nbsp;
            <Logo color="-white" className="" width={0} height={0} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={aboutMarkExecuteScroll}
                className="nav-link"
                aria-current="page"
              >
                {t("aboutMark")}
              </Nav.Link>
              <Nav.Link
                href="https://www.sinaha.ae"
                target="_blank"
                className="nav-link"
              >
                {t("sinaha")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={factoriesExecuteScroll}
                className="nav-link"
              >
                {t("factories")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={productsExecuteScroll}
                className="nav-link"
              >
                {t("products")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={catalogExecuteScroll}
                className="nav-link"
              >
                {t("catalog")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={newsExecuteScroll}
                className="nav-link"
              >
                {t("news")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={faqsExecuteScroll}
                className="nav-link"
              >
                {t("faqs")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={galleryExecuteScroll}
                className="nav-link"
              >
                {t("gallery")}
              </Nav.Link>
              <Nav.Link
                href={from === "home" ? "" : current_location}
                onClick={contactExecuteScroll}
                className="nav-link"
              >
                {t("contact")}
              </Nav.Link>
            </Nav>
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                  <FontAwesomeIcon icon={faLanguage} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => changeLanguage(e, "en")}>
                    <img
                      src="/assets/images/en.png"
                      style={{ width: "20px" }}
                    />
                    &nbsp;&nbsp; <span>English </span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => changeLanguage(e, "ar")}>
                    <img
                      src="/assets/images/ar.png"
                      style={{ width: "20px" }}
                    />
                    &nbsp;&nbsp;
                    <span>العربية </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder={t("search")}
                className="me-2"
                aria-label="Search"
              />
              <button
                className="btn btn-success btn-outline-light"
                type="submit"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </Form> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
