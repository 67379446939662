import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";

function Slider() {
  const { t, i18n } = useTranslation();
  const slidersItems = [
    {
      type: "video",
      media: "/assets/images/made-in-the-emirates-2024-04-15.mp4",
      format: "mp4",
    },
    {
      type: "image",
      media:
        "/assets/images/slider/slide-1" +
        (i18n.language === "ar" ? "" : "-en") +
        ".jpg",
      format: "jpg",
    },
    {
      type: "image",
      media:
        "/assets/images/slider/slide-2" +
        (i18n.language === "ar" ? "" : "-en") +
        ".jpg",
      format: "jpg",
    },
  ];
  return (
    <Carousel interval={null} className="slider">
      {slidersItems.map(function (slide, i) {
        return (
          <Carousel.Item key={i}>
            <div className="carousel-item-overlay"></div>
            {slide.type === "video" ? (
              <video width="320" height="240" loop muted autoPlay>
                <source src={slide.media} type={`video/${slide.format}`} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={slide.media}
                className="w-100 h-100 object-position-top object-fit-fill"
                alt="Github"
                width={600}
                height={450}
                loading="lazy"
              />
            )}
            <div className="container">
              <div className="carousel-caption text-start">
                <h1>{t(`slide-${i + 1}-title`)}</h1>
                <p className="opacity-75">{t(`slide-${i + 1}-description`)}</p>
                <p>
                  <a className="btn btn-lg btn-success" href="#">
                    {t(`readMore`)}
                  </a>
                </p>
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default Slider;
