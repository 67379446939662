import { React, lazy, Suspense, useRef } from "react";
import AboutMark from "./aboutMark";
import Gallery from "./gallery";
import Contact from "./contact";
import Catalog from "./catalog";
import Header from "./header.";
import Slider from "./slider";
import Faqs from "./faqs";
import News from "./news";

const ProductsSlider = lazy(() => import("./productsSlider"));
const Factories = lazy(() => import("./factories"));

const useScrollAboutMark = (id) => {
  const aboutMarkRef = useRef(null);
  const aboutMarkExecuteScroll = () =>
    aboutMarkRef.current.scrollIntoView({ behavior: "smooth" });
  return [aboutMarkExecuteScroll, aboutMarkRef];
};
const useScrollFactories = (id) => {
  const factoriesRef = useRef(null);
  const factoriesExecuteScroll = () =>
    factoriesRef.current.scrollIntoView({ behavior: "smooth" });
  return [factoriesExecuteScroll, factoriesRef];
};
const useScrollProducts = (id) => {
  const productsRef = useRef(null);
  const productsExecuteScroll = () =>
    productsRef.current.scrollIntoView({ behavior: "smooth" });
  return [productsExecuteScroll, productsRef];
};
const useScrollNews = (id) => {
  const newsRef = useRef(null);
  const newsExecuteScroll = () =>
    newsRef.current.scrollIntoView({ behavior: "smooth" });
  return [newsExecuteScroll, newsRef];
};
const useScrollCatalog = (id) => {
  const catalogRef = useRef(null);
  const catalogExecuteScroll = () =>
    catalogRef.current.scrollIntoView({ behavior: "smooth" });
  return [catalogExecuteScroll, catalogRef];
};
const useScrollContact = (id) => {
  const contactRef = useRef(null);
  const contactExecuteScroll = () =>
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  return [contactExecuteScroll, contactRef];
};
const useScrollFaqs = (id) => {
  const faqsRef = useRef(null);
  const faqsExecuteScroll = () =>
    faqsRef.current.scrollIntoView({ behavior: "smooth" });
  return [faqsExecuteScroll, faqsRef];
};
const useScrollGallery = (id) => {
  const galleryRef = useRef(null);
  const galleryExecuteScroll = () =>
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  return [galleryExecuteScroll, galleryRef];
};

function Home() {
  const [aboutMarkExecuteScroll, aboutMarkRef] = useScrollAboutMark();
  const [factoriesExecuteScroll, factoriesRef] = useScrollFactories();
  const [productsExecuteScroll, productsRef] = useScrollProducts();
  const [galleryExecuteScroll, galleryRef] = useScrollGallery();
  const [catalogExecuteScroll, catalogRef] = useScrollCatalog();
  const [contactExecuteScroll, contactRef] = useScrollContact();
  const [newsExecuteScroll, newsRef] = useScrollNews();
  const [faqsExecuteScroll, faqsRef] = useScrollFaqs();

  return (
    <>
      <Header
        aboutMarkExecuteScroll={aboutMarkExecuteScroll}
        factoriesExecuteScroll={factoriesExecuteScroll}
        productsExecuteScroll={productsExecuteScroll}
        catalogExecuteScroll={catalogExecuteScroll}
        contactExecuteScroll={contactExecuteScroll}
        newsExecuteScroll={newsExecuteScroll}
        faqsExecuteScroll={faqsExecuteScroll}
        galleryExecuteScroll={galleryExecuteScroll}
        from="home"
      />
      <Slider />
      <main>
        <div className="container">
          <AboutMark innerRef={aboutMarkRef} />
          <hr className="featurette-divider" />
          {/* <AboutMoiat /> */}
          {/* <hr className="featurette-divider" /> */}
          <Suspense fallback={<div>Loading Component</div>}>
            <Factories innerRef={factoriesRef} limit={5} source="Home" />
          </Suspense>
          <hr className="featurette-divider" />
          <Suspense fallback={<div>Loading Component</div>}>
            <ProductsSlider innerRef={productsRef} source="Home" />
          </Suspense>
          <hr className="featurette-divider" />
          <Catalog innerRef={catalogRef} />
          <hr className="featurette-divider" />
          <News innerRef={newsRef} />
          <hr className="featurette-divider" />
          <Faqs innerRef={faqsRef} />
          <hr className="featurette-divider" />
          <Gallery innerRef={galleryRef} />
          <hr className="featurette-divider" />
          <Contact innerRef={contactRef} />
        </div>
      </main>
    </>
  );
}

export default Home;
