import React from "react";
import Logo from "./logo";
import { useTranslation } from "react-i18next";

function AboutMark({ innerRef }) {
  const { t } = useTranslation();
  return (
    <section ref={innerRef} id="aboutMark">
      <div className="row featurette">
        <div className="col-md-8">
          <h1>{t("aboutMadeInTheEmiratesMark")}</h1>
          <p className="text-body-secondary">{t("powerfulTool")}</p>
          <p className="lead">
            <b>“{t("madeInTheEmirates")}”</b> {t("markDefinition")}
          </p>
        </div>
        <div className="col-md-4">
          <Logo color="" className="w-100 h-auto" width={600} height={450} />
        </div>
      </div>
      <h1>{t("goals")}</h1>
      <p className="text-body-secondary">{t("madeInTheEmiratesMarkAimsTo")}</p>
      <div className="row text-center mt-5">
        <div className="col-4">
          <div className="icon">1</div>
          <p className="object-title">
            {t("marketEmiratiProductsWithStatementnt")}
            <br />“{t("madeInTheEmirates")}”
          </p>
        </div>
        <div className="col-4">
          <div className="icon">2</div>
          <p className="object-title">
            {t("enhanceQualityOf")}
            <br /> {t("nationalProducts")}
          </p>
        </div>
        <div className="col-4">
          <div className="icon">3</div>
          <p className="object-title">
            {t("opportunityFor")}
            <br /> {t("newMarkets")}
          </p>
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col-md-10">
          <h1>{t("serviceTitle")}</h1>
          <p className="lead">
            {t("serviceDescription")}
            <a href="www.moiat.gov.ae" target="_blank">
              www.moiat.gov.ae
            </a>
          </p>
          <h4>
            <strong> {t("serviceFeesTitle")} </strong>
          </h4>
          <p className="lead">{t("serviceFees")}</p>
          <a
            className="btn btn-success"
            target="_blank"
            href="https://moiat.gov.ae/en/services/issue-license-to-use-made-in-the-emirates"
          >
            {t("applyNow")}
          </a>
        </div>
        <div className="col-md-2">
          <h6 className="text-center">{t("scantoApply")}</h6>
          <img
            src="/assets/images/service-qr-code.png"
            className="w-100 h-auto qrcode"
            alt="service qr code"
            width={600}
            height={450}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}

export default AboutMark;
