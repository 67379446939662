import React from "react";
import { useTranslation } from "react-i18next";

function Gallery({ innerRef }) {
  const { t } = useTranslation();
  return (
    <section id="gallery" ref={innerRef}>
      <h1>{t("gallery")}</h1>
      <p className="text-body-secondary">{t("madeInTheEmiratesGallery")}</p>
      <div className="row g-2">
        <div className="col-md-3">
          <iframe
            className="w-100"
            height="315"
            src="https://www.youtube.com/embed/KTdio8dRDP0?si=QLk7MNzlqow9Wu4x"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-3">
          <iframe
            className="w-100"
            height="315"
            src="https://www.youtube.com/embed/DwMV5dGqTEY?si=6YpeulYPwNZf1QiA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-3">
          <iframe
            className="w-100"
            height="315"
            src="https://www.youtube.com/embed/YJyAUAgXDrY?si=EgJ3DPQLIcfNSOne"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="col-md-3">
          <iframe
            className="w-100"
            height="315"
            src="https://www.youtube.com/embed/IjOa_iy2_Bo?si=YDZgkbXLneeVnebL"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
