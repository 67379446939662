import { React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaintbrush,
  faArrowsRotate,
  faShareNodes,
  faQrcode,
  faLeaf,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Catalog({ innerRef }) {
  const { t, i18n } = useTranslation();
  const featuresList = [
    {
      icon: <FontAwesomeIcon icon={faPaintbrush} />,
    },
    {
      icon: <FontAwesomeIcon icon={faArrowsRotate} />,
    },
    {
      icon: <FontAwesomeIcon icon={faShareNodes} />,
    },
    {
      icon: <FontAwesomeIcon icon={faQrcode} />,
    },

    {
      icon: <FontAwesomeIcon icon={faLeaf} />,
    },
    {
      icon: <FontAwesomeIcon icon={faMagnifyingGlass} />,
    },
  ];
  return (
    <section id="catalog" ref={innerRef}>
      <div className="container sin-products-list mt-3">
        <h1>{t("productsSmartcatalog")}</h1>
        <p className="text-body-secondary">
          {t("theSmartCatalogForAllProductsWithMadeintheEmiratesMark")}
        </p>
        <div className="row">
          <div className="col-md-7">
            <h6 className="my-3">
              <b>{t("productsSmartCatalogFeatures")}</b>
            </h6>
            <ul style={{ listStyleType: "none" }}>
              {featuresList.map((feature, key) => {
                return (
                  <li key={key}>
                    <span className="features-icon">{feature.icon}</span>
                    {t(`feature-${key + 1}`)}
                  </li>
                );
              })}
            </ul>
            <a
              href={`https://sinaha.ae/${i18n.language}/made-in-the-emirates-catalog`}
              target="_blank"
              className="btn btn-success"
            >
              {t("openTheCatalog")}
            </a>
          </div>
          <div className="col-md-5">
            <img
              src={
                i18n.language === "ar"
                  ? "/assets/images/catalog-image-ar.png"
                  : "/assets/images/catalog-image.png"
              }
              className="w-100"
              alt="Products' Smart Catalog"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Catalog;
