import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";

function Contact({ innerRef }) {
  const { t, i18n } = useTranslation();
  return (
    <section id="contact" ref={innerRef}>
      <h1>{t("contact")}</h1>
      <p className="text-body-secondary">{t("sendMessageForAnyInquire")}</p>
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          {/* <img
            src="/assets/images/customer-service-svgrepo-com.svg"
            className="object-fit-cover object-position-top"
            alt="contact"
            width={200}
            height={150}
            loading="lazy"
          /> */}
          <div className="d-flex flex-column justify-content-around h-100">
            <div className="d-flex mb-3">
              <div
                className={i18n.language === "ar" ? "ms-3 fs-3" : "me-3 fs-3"}
              >
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
              <div>
                <h3>{t("address")}</h3>
                <h6 className="txt3">
                  <a
                    href="https://www.google.com/maps/place/Sinaha/@24.3730071,54.5383618,15z/data=!4m2!3m1!1s0x0:0x918a9bcdcac779dc?sa=X&ved=1t:2428&ictx=111"
                    target="_blank"
                  >
                    {t("sinahaAddress")}
                  </a>
                </h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div
                className={i18n.language === "ar" ? "ms-3 fs-3" : "me-3 fs-3"}
              >
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div>
                <h3>{t("talk")}</h3>
                <h6 className="txt3">
                  <a href="tel:+97125558989">+971 255 589 89</a>
                </h6>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div
                className={i18n.language === "ar" ? "ms-3 fs-3" : "me-3 fs-3"}
              >
                <FontAwesomeIcon icon={faCalendarDays} />
              </div>
              <div>
                <h3>{t("worksHours")}</h3>
                <div className="txt3">
                  <p className="mb-0"> {t("worksHour-1")} </p>
                  <p className="mb-0"> {t("worksHour-2")} </p>
                  <p className="mb-0"> {t("worksHour-3")} </p>
                </div>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div
                className={i18n.language === "ar" ? "ms-3 fs-3" : "me-3 fs-3"}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div>
                <h3>{t("gsupport")}</h3>
                <h6 className="txt3">
                  <a href="mailto:info@sinaha.ae">info@sinaha.ae</a>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <form>
            <div className="form-group mb-3">
              <label htmlFor="name">{t("name")}</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder={t("enterName")}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">{t("email")}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder={t("enterEmail")}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="subject">{t("subject")}</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder={t("enterSubject")}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="message">{t("message")}</label>
              <textarea
                className="form-control"
                cols="30"
                rows="10"
                id="message"
                placeholder={t("enterMessage")}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-success btn-outline-light">
              {t("send")}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
