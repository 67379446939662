import React from "react";
import Logo from "./logo";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSortUp } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  const { t, i18n } = useTranslation();
  return (
    // <footer className="container">
    //   <hr className="featurette-divider" />
    //   <p className="float-end">
    //     <a href="#">{t("backToTop")}</a>
    //   </p>
    //   <p>
    //     &copy; 2024 {t("sinahaPlatform")}
    //     <br />
    //     <a
    //       href="https://sinaha.ae/index.php?route=information/information&information_id=3"
    //       target="_blank"
    //     >
    //       {t("privacy")}
    //     </a>
    //     &middot;
    //     <a
    //       href="https://sinaha.ae/index.php?route=information/information&information_id=17"
    //       target="_blank"
    //     >
    //       {t("terms")}
    //     </a>
    //   </p>
    // </footer>
    <>
      <hr className="featurette-divider" />
      <footer className="w-100 py-4 flex-shrink-0">
        <div className="container py-4">
          <div className="row gy-4 gx-5">
            <div className="col-lg-4 col-md-6">
              <h5 className="h1 text-white">
                <Logo
                  color="-white"
                  className="w-96 h-auto"
                  width={600}
                  height={450}
                />
              </h5>
              <p className="small text-white">{t("markDefinition-2")}</p>
              <p className="small text-white mb-0">
                &copy; 2024 {t("sinahaPlatform")}
              </p>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-white mb-3">{t("quickLinks")}</h5>
              <ul className="list-unstyled text-white p-0">
                <li className="mb-2">
                  <a href="https://moiat.gov.ae/" target="_blank">
                    {t("MOIAT")}
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://moiat.gov.ae/en/services/issue-license-to-use-made-in-the-emirates"
                    target="_blank"
                  >
                    {t("issueLicense")}
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://moiat.gov.ae/en/make-it-in-the-emirates"
                    target="_blank"
                  >
                    {t("makeItInTheEmirates")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6">
              <h5 className="text-white mb-3">{t("quickLinks")}</h5>
              <ul className="list-unstyled text-white p-0">
                <li className="mb-2">
                  <a href="https://sinaha.ae" target="_blank">
                    {t("sinahaPlatform")}
                  </a>
                </li>
                <li className="mb-2">
                  <a href="https://sinaha.ae/en/invest-in-uae" target="_blank">
                    {t("investInUAE")}
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://sinahaqt.sinaha.ae/forms/ticket?styled=1&with_logo=1"
                    target="_blank"
                  >
                    {t("support")}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-4 col-md-6"
              style={
                i18n.language === "ar"
                  ? {
                      borderRight: "1px solid #fff",
                    }
                  : {
                      borderLeft: "1px solid #fff",
                    }
              }
            >
              <h5 className="text-white mb-3">{t("newsletter")}</h5>
              <p className="small text-white">{t("newsletterDescription")}</p>
              <a href="#" className="go-up">
                <FontAwesomeIcon icon={faSortUp} />
              </a>
              <form action="#">
                <div className="input-group mb-3">
                  <input
                    className={
                      (i18n.language === "ar" && " border-radius-ar-2 ") +
                      " form-control "
                    }
                    type="text"
                    placeholder={t("enterEmail")}
                    aria-label={t("enterEmail")}
                    aria-describedby="button-addon2"
                  />
                  <button
                    className={
                      (i18n.language === "ar" && "border-radius-ar ") +
                      " btn btn-success "
                    }
                    id="button-addon2"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
